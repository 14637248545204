// Import Firebase SDK
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import React from "react";

// Firebase Configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDfXFXxC_ni6bxBnxKT1hEdRSfU7NCmnA",
  authDomain: "dolphinchat-fd9f6.firebaseapp.com",
  projectId: "dolphinchat-fd9f6",
  storageBucket: "dolphinchat-fd9f6.firebasestorage.app",
  messagingSenderId: "999631797121",
  appId: "1:999631797121:web:8d0c65a8b0bec1e3aa4680",
  measurementId: "G-Y0P8P41T4X",
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Request Notification Permission and Get Token
// export const requestNotificationPermission = async () => {
//   try {
//     const permission = await Notification.requestPermission();
//     console.log("Notification permission status:", permission);

//     if (permission === "granted") {
//       const currentToken = await getToken(messaging, {
//         vapidKey:
//           "BAvXMB93ISNwt3UoS7YwUMelCn233f3SHyAbcdl7OnPZwtoE-8vlU12wwSii7HOusmbbk2gkJ_AL7sQWao-XXUE",
//       });

//       if (currentToken) {
//         console.log("FCM Token:", currentToken);
//         await saveTokenToBackend(currentToken);
//       } else {
//         console.error("No registration token available.");
//       }
//     } else {
//       console.warn("Notifications permission denied.");
//     }
//   } catch (error) {
//     console.error("Error requesting notification permission:", error);
//   }
// };

// // Save Token to Backend
// const saveTokenToBackend = async (token) => {
//   try {
//     const response = await fetch(
//       "/notification/Chrome-notification/save-token",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ token }),
//       }
//     );
//     console.log("Token sent to backend successfully:", response);
//   } catch (error) {
//     console.error("Error sending token to backend:", error);
//   }
// };

// Handle Incoming Messages
export const onMessageListener = () => {
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Message received:", payload);

      const notificationTitle =
        payload.notification?.title || "New Notification";
      const notificationBody =
        payload.notification?.body || "You have a new message.";

      // Show browser notification
      new Notification(notificationTitle, {
        body: notificationBody,
      });

      resolve(payload);
    });
  });
};

// Request Notification Permission and Get Token
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    console.log("Notification permission status:", permission);

    if (permission === "granted") {
      const currentToken = await getToken(messaging, {
        vapidKey:
          "BAvXMB93ISNwt3UoS7YwUMelCn233f3SHyAbcdl7OnPZwtoE-8vlU12wwSii7HOusmbbk2gkJ_AL7sQWao-XXUE",
      });

      if (currentToken) {
        console.log("FCM Token:", currentToken);
        await saveTokenToBackend(currentToken);
      } else {
        console.error("No registration token available.");
      }
    } else {
      console.warn("Notifications permission denied.");
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};

// Save Token to Backend
const saveTokenToBackend = async (token) => {
  try {
    const response = await fetch(
      "/notification/Chrome-notification/save-token",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token }),
      }
    );
    console.log("Token sent to backend successfully:", response);
  } catch (error) {
    console.error("Error sending token to backend:", error);
  }
};

// Ensure you are calling this inside a useEffect or as per your component lifecycle
export default function PushNotification() {
  React.useEffect(() => {
    requestNotificationPermission();
  }, []);

  return <div>Push Notification Initialized</div>;
}
