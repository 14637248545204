import React from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { FileCopy, Info } from "@mui/icons-material";
import { Tooltip, InputAdornment, IconButton } from "@mui/material";

import { alert } from "../../../../redux/slices/alertSlice";
import TextField from "./../../../../components/TextField";

export default function TextFieldComponent({
  formikProps,
  fieldDetails,
  disabled,
  FileCopy = false,
  ...props
}) {
  const dispatch = useDispatch();

  if (!!formikProps) {
    return (
      <TextField
        required={fieldDetails.mandatory}
        name={fieldDetails.name}
        label={fieldDetails.label}
        disabled={disabled}
        formikProps={formikProps}
        multiline={fieldDetails.multiline}
        rows={fieldDetails.rows}
        InputProps={
          FileCopy && {
            style: {
              paddingRight: 0,
            },
            endAdornment: (
              <InputAdornment position="end">
                {!!fieldDetails.copyable && (
                  <Tooltip title={"Copy " + fieldDetails.label}>
                    <IconButton
                      disableFocusRipple
                      style={{ padding: "0 10px 0" }}
                      color="primary"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          _.get(formikProps.values, fieldDetails.name)
                        );
                        dispatch(
                          alert({
                            type: "success",
                            message: "Copied to clipboard!",
                          })
                        );
                      }}
                    >
                      <FileCopy fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}

                {!!fieldDetails.tooltip && (
                  <Tooltip title={fieldDetails.tooltip}>
                    <IconButton
                      disableFocusRipple
                      style={{
                        color: "#cbcbcb",
                        padding: "0px",
                        margin: "10px",
                      }}
                    >
                      <Info />
                    </IconButton>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }
        }
        {...props}
      />
    );
  }
}
