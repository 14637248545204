import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";

import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Typography,
} from "@mui/material";

import DrivingLicence from "./DrivingLicence/DrivingLicence";
import { useAxios, useAxiosV2, useComponent } from "../../hooks/";
import Aadharverificaion from "./Aadharverificaion/Aadharverificaion";

import { SubheaderSection, LoadingButton } from "./../../components";

import styled from "styled-components";
import Error from "../Error/Error";
import EventBookingSuccess from "../Success/EventBookingSuccess";
import pages from "../../constants/pages";

const CustomFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiOutlinedInput-root ": {
    "& fieldset": {
      borderColor: "rgb(232, 222, 207) !important",
      borderRadius: "12.5px",
    },
    "&:hover fieldset": {
      borderColor: "rgb(161, 130, 74)",
      borderRadius: "12.5px",
    },
    "&.Mui-focused fieldset": {
      borderColor: " rgb(161, 130, 74)",
      borderRadius: "12.5px",
    },
  },

  "&.MuiSelect-icon": {
    color: " rgb(161, 130, 74) !important",
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    borderColor: "1px solid rgb(232, 222, 207)",
  },

  ".MuiInputLabel-root": {
    color: "rgb(161, 130, 74)",
  },
}));

const WelcomeScreen = ({ setAadharVerified }) => {
  const dispatch = useDispatch();
  const { loader } = useComponent();
  const axiosVerismart = useAxios({ baseURL: "mox_api_2" });
  const axiosAadhar = useAxiosV2({ baseURL: "aadharUrl" });

  const [openError, setOpenError] = useState(false);
  const [consent, setConsent] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [token, setToken] = React.useState("");
  const [verification, setVerification] = useState(false);
  const [identityType, setIdentityType] = useState("");
  const [eventDetail, setEventDetail] = useState(false);
  const [documentsList, setDocumentsList] = useState([]);
  const [verificationFailedCount, setVerificationFailedCount] =
    React.useState(0);
  const [displayMessage, setDisplayMessage] = useState(undefined);

  const [redirectPageLink, setRedirectPageLink] = useState("");

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const eventId = queryParams.get("event_id");
  const visitorId = queryParams.get("visitor_id");
  const bookingId = queryParams.get("booking_id");
  const bookingType = queryParams.get("booking_type");
  const appointmentID = queryParams.get("appointment_id");

  if (bookingId !== null) {
    sessionStorage.setItem("bookingId", bookingId);
  }

  if (visitorId !== null) {
    sessionStorage.setItem("visitorId", visitorId);
  }

  if (eventId !== null) {
    sessionStorage.setItem("eventId", eventId);
  }

  const [bookingStatus, setBookingStatus] = useState({
    booking_already_verified: false,
    booking_exists: undefined,
    face_match: false,
    name_match: false,
    qr_link: "",
  });

  const localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return item.includes(visitorId);
  });

  useEffect(() => {
    let faceData = digipassDataKey.filter((item) => {
      return localStorage.getItem(item);
    });

    if (faceData.length !== 0 && appointmentID != -1) {
      axiosVerismart({
        url: `event/verify/booking-status`,
        method: "POST",
        disableRedirect: true,
        data: {
          name: "",
          face_data: "",
          ...(bookingId && { booking_id: bookingId }),
          ...(appointmentID && { appointment_id: appointmentID }),
        },
      }).then((response) => {
        if (response?.data?.send_consent) {
          const destination = `${pages?.Consent?.route}/?booking_id=${
            bookingId || sessionStorage.getItem("bookingId")
          }&visitorId=${visitorId}`;

          navigate(destination, {
            state: {
              visitor_id: visitorId,
              bookingId: bookingId,
              eventId: eventId,
            },
          });
        }

        if (response.status) {
          if (!response.data.is_valid) {
            setOpenError(response.data);
          }
          if (response.data.is_verified) {
            setOpenSuccess(true);
          }

          setRedirectPageLink(response?.data?.page_link);

          setDisplayMessage(response.message.displayMessage);
        } else {
          setOpenError(true);

          setDisplayMessage(response.displayMessage);
        }
      });
    }

    loader.start();

    axiosAadhar({
      url: `/login/`,
      method: "POST",
      data: {
        client_secret: "dmVyaXNtYXJ0",
      },
    })
      .then((response) => {
        if (response.success) {
          setToken(response.access_token);
          loader.stop();
        } else {
          dispatch(
            alert({
              type: "error",
              message: "Token generation failed",
            })
          );
          loader.stop();
        }
      })
      .catch((error) => {
        console.error("Error in token generation ", error);
      });

    if (Boolean(eventId)) {
      axiosVerismart({
        url: "event/booking/documents?event_id=" + eventId,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setDocumentsList(response.data);
        } else {
          setOpenError(true);

          setDisplayMessage(response.displayMessage);
        }
      });

      axiosVerismart({
        url: "admin/events/event-details?event_id=" + eventId,
        method: "GET",
        customAuthToken: "9beaa95b-c59f-4ec9-bb60-5f4ee1986311",
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setEventDetail(response.data);
        } else {
          setOpenError(true);

          setDisplayMessage(response.displayMessage);
        }
      });
    } else {
      axiosVerismart({
        url: "event/booking/documents?appointment_id=" + appointmentID,
        disableRedirect: true,
      }).then((response) => {
        if (response.status) {
          setDocumentsList(response.data);
        } else {
          setOpenError(true);

          setDisplayMessage(response.displayMessage);
        }
      });
    }
  }, []);

  const handleProceed = () => {
    setVerification(true);
  };
  const handleIdentityTypeChange = (event) => {
    setIdentityType(event.target.value);
  };

  if (verificationFailedCount > 4) {
    window.location.reload();
  }

  return (
    <Fragment>
      <Grid container className="verificationOuterBox">
        <Grid item container xs={6} className="companyBanner">
          <img
            className="eventImg"
            width="100%"
            height="100%"
            style={{ borderRadius: "12.5px" }}
            src={
              eventDetail.banner_image ??
              "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/3e9c86447ad14afb8b260fe48efe0eb0/1724237319019_image-cache.jpeg"
            }
          />
        </Grid>

        <Grid item container className="eventImageBox">
          <div
            style={{ width: "100%", position: "relative", overflow: "hidden" }}
          >
            <img
              style={{
                width: "100%",
                height: "35vh",
              }}
              src={
                Boolean(appointmentID)
                  ? "https://dolphinchat-chat.s3.ap-south-1.amazonaws.com/publicURLS/a2d75083a653441c8541c4de8c0e3088/1728475638747_appointment.png"
                  : eventDetail?.banner_image
              }
            />
          </div>
        </Grid>

        {!Boolean(verification) && (
          <Fragment>
            {
              <Fragment>
                <Grid item sl={6} padding={"25px"} alignContent={"center"}>
                  <SubheaderSection
                    textAlign="left"
                    title="Document Verification"
                    // content="Choose an Identification Document from the Options Below"
                  />

                  <form>
                    <CustomFormControl
                      fullWidth
                      variant="outlined"
                      sx={{ marginBottom: "16px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Select Document
                      </InputLabel>
                      <Select
                        label="Select Booking Type"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        onChange={handleIdentityTypeChange}
                        value={identityType}
                        name="identityType"
                      >
                        {documentsList.map((item) => {
                          return (
                            <MenuItem value={item.document_id}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </CustomFormControl>
                    <LoadingButton
                      disabled={identityType === ""}
                      onClick={handleProceed}
                    >
                      Proceed
                    </LoadingButton>
                  </form>
                </Grid>
              </Fragment>
            }
          </Fragment>
        )}

        {Boolean(identityType === 1 && token && verification) && (
          <Aadharverificaion
            setAadharVerified={setAadharVerified}
            token={token}
            visitorId={visitorId}
            bookingType={bookingType}
            eventId={eventId}
            setVerificationFailedCount={setVerificationFailedCount}
            verificationFailedCount={verificationFailedCount}
            setVerification={setVerification}
          />
        )}

        {Boolean(identityType === 2 && token && verification) && (
          <DrivingLicence
            token={token}
            visitorId={visitorId}
            bookingType={bookingType}
            eventId={eventId}
            setVerificationFailedCount={setVerificationFailedCount}
            verificationFailedCount={verificationFailedCount}
            setVerification={setVerification}
          />
        )}

        <Error
          open={openError}
          CloseButton={false}
          setOpen={setOpenError}
          ErrorMessage={displayMessage}
        />

        <EventBookingSuccess
          open={openSuccess}
          setOpen={setOpenSuccess}
          SuccessMessage={displayMessage}
          handleOnClick={() => {
            navigate(redirectPageLink);
          }}
          buttonContent={redirectPageLink !== "" ? "Next" : "Next Step"}
        />
      </Grid>
    </Fragment>
  );
};

export default WelcomeScreen;
