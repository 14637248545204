import { Fragment } from "react";
import TextFieldComponent from "../../custom fields/textfield dialog components/TextFieldComponent";
import DatePickerComponent from "../../custom fields/date dialog components/DatePicker";
import DropDownComponent from "../../custom fields/dropdown dialog components/DropdownComponent";
import SwitchComponent from "../../custom fields/switch dialog components/SwitchComponent";

export default function CustomField({
  formikProps,
  customFields,
  visitorDataIndex,
}) {
  return (
    <Fragment>
      {customFields.map((item, index) => {
        return (
          <Fragment>
            {item.control_id === "61dd2dfa1fb38be82e077ed7" && (
              <TextFieldComponent
                name={`visitorData[${visitorDataIndex}].${item.name}`}
                fieldDetails={item}
                formikProps={formikProps}
                disabled={!item.editable}
              />
            )}

            {item.control_id === "61dd2dfa1fb38be82e077ed9" && (
              <DatePickerComponent
                formikProps={formikProps}
                fieldDetails={item}
                disabled={!item.editable}
              />
            )}

            {item.control_id === "61dd2dfa1fb38be82e077ed8" && (
              <DropDownComponent
                formikProps={formikProps}
                fieldDetails={item}
                disabled={!item.editable}
              />
            )}

            {item.control_id === "61e13a0bf2c60729b06385f3" && (
              <SwitchComponent
                formikProps={formikProps}
                fieldDetails={item}
                disabled={!item.editable}
              />
            )}
          </Fragment>
        );
      })}
    </Fragment>
  );
}
