import { Formik } from "formik";
import { Fragment, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { useAlert, useAxios } from "../../hooks";
import { LoadingButton, SelectField, TextField } from "../../components";

export default function Dependent({ setDependentList }) {
  const axios = useAxios();
  const { alert } = useAlert();
  const dispatch = useDispatch();
  const { visitorData } = useSelector((state) => state.memberDetails);

  const [formData, setFormData] = useState({
    phoneNumber: visitorData.phoneNumber,
    name: "",
    emailId: "",
    gender: "",
  });

  const [loading, setLoading] = useState(false);

  return (
    <Fragment>
      <Formik
        initialValues={formData}
        onSubmit={(formData, { setSubmitting, resetForm }) => {
          setLoading(true);
          axios({
            url: "event/visitor/add-dependent",
            method: "POST",
            data: {
              phone_number: formData.phoneNumber,
              name: formData.name,
              email_id: formData.emailId,
              gender: formData.gender,
            },
          }).then((response) => {
            if (response.status) {
              dispatch(
                alert({
                  type: "success",
                  message: response.message.displayMessage,
                })
              );

              axios({
                url:
                  "event/visitor/get-dependent-list?phone_number=" +
                  visitorData.phoneNumber,
                disableRedirect: true,
              }).then((response) => {
                if (response.status) {
                  setDependentList(response.data || []);
                }
              });
            }
            resetForm();
            setLoading(false);
          });
        }}
      >
        {(formikProps) => {
          return (
            <Fragment>
              <Grid item xs={12}>
                <Typography variant="h5" fontWeight={"900"}>
                  Add Dependent
                </Typography>
              </Grid>

              <Grid item container xs={6}>
                <TextField
                  name="name"
                  label={"Fill Name"}
                  formikProps={formikProps}
                />
              </Grid>

              <Grid item container xs={6}>
                <TextField
                  name="phoneNumber"
                  label={"Phone Number"}
                  disabled
                  formikProps={formikProps}
                />
              </Grid>

              <Grid item container xs={6}>
                <TextField
                  name="emailId"
                  label={"Email Id"}
                  formikProps={formikProps}
                />
              </Grid>

              <Grid item container xs={6}>
                <SelectField
                  label="Select Your Gender"
                  name="gender"
                  value={formikProps.values.gender}
                  options={[
                    { id: "Male", name: "Male" },
                    { id: "Female", name: "Female" },
                  ]}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  error={
                    formikProps.touched.district && formikProps.errors.district
                  }
                  required
                  xs={12}
                />
              </Grid>

              <LoadingButton
                loading={loading}
                onClick={formikProps.handleSubmit}
                fullWidth
                backgroundColor="#F5F0E5"
                style={{ fontWeight: 800, color: "black" }}
              >
                Submit
              </LoadingButton>
            </Fragment>
          );
        }}
      </Formik>
    </Fragment>
  );
}
