export const eventBokingObject = {
  event: "",
  eventAmount: 0,
  memberCount: 0,
  paymentStatue: "",
  vehicle: [],
  visitorData: [],
};

export function objectFromLocationData(
  data,
  eventObject,
  subEventOptions,
  visitorData
) {
  let value = { visitorData: [{}] };

  if (Object.keys(data || {})?.includes("userData")) {
    data?.userData.map((item, index) => {
      value[`name${index}`] =
        item?.visitor_info?.first_name || item?.first_name;

      value[`phone_number${index}`] =
        item?.phone_number ||
        item?.visitor_info?.phone_number ||
        sessionStorage.getItem("mobileNumber");

      value[`email_id${index}`] =
        item?.email_id ||
        item?.visitor_info?.email_id ||
        sessionStorage?.getItem("email");

      value.event = item?.event_id;

      value.eventAmount = eventObject?.ticket_cost;

      value[`state${index}`] = item?.state_id;

      value[`district${index}`] = item?.district_id;

      value.organization = item?.organization_name || "";

      value.designation = item?.designation || "";

      value[`subEvent${index}`] = item?.badge_id;

      value[`subEventAmount${index}`] = item?.badge_id_cost;

      value[`gender${index}`] = item?.gender || item?.visitor_info?.gender;

      value.memberCount = index;

      value.paymentStatue = item?.payment_status;
    });
  } else {
    value.visitorData[0].name =
      (data?.first_name && data?.last_name
        ? `${data.first_name} ${data.last_name}`
        : data?.visitor_info?.first_name && data?.visitor_info?.last_name
        ? `${data.visitor_info.first_name} ${data.visitor_info.last_name}`
        : visitorData?.firstName) || "";
    value.visitorData[0].phone_number =
      data?.phone_number ||
      data?.visitor_info?.phone_number ||
      visitorData.phoneNumber ||
      sessionStorage.getItem("mobileNumber");
    value.visitorData[0].email_id =
      data?.email_id ||
      visitorData.emailID ||
      data?.visitor_info?.email_id ||
      sessionStorage?.getItem("email");
    value.event = data?.event_id;
    value.eventAmount = eventObject?.ticket_cost;
    value.visitorData[0].state = data?.state_id;
    value.visitorData[0].district = data?.district_id;
    value.organization = data?.organization_name || "";
    value.designation = data?.designation || "";
    value.visitorData[0].badge_id = data?.badge_id;
    value.visitorData[0].badge_id_amount = data?.badge_id_cost;
    value.visitorData[0].gender =
      data?.gender || data?.visitor_info?.gender || visitorData.gender;

    value.paymentStatue = data?.payment_status;

    value.memberCount = 0;
  }

  return value;
}

export function objectFromData(data, id, badge) {
  let formData = data.visitorData.map((item, index) => {
    return {
      ...item,
      booking_date: Date.now() / 1000,
      ...(index > 0 && { is_dependent: true }),
      visitor_id: id,
    };
  });

  let totaBadgeValue = data.visitorData.reduce((sum, current, index) => {
    return current?.badge_id_amount || 0 + sum;
  }, 0);

  return {
    event_id: data.event,
    total_price: data.eventAmount * data.visitorData.length + totaBadgeValue,
    reference_visitor_id: id,
    visitors: formData,
  };
}
