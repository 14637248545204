import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { get } from "lodash";

import TextField from "./../../../../components/TextField";

export default function DatePickerComponent({
  formikProps,
  fieldDetails,
  disabled,
}) {
  if (fieldDetails.start_date >= 0) {
    var startDate = new Date(
      new Date().setDate(new Date().getDate() - fieldDetails.start_date)
    );
  }

  if (fieldDetails.end_date >= 0) {
    var endDate;
    if (fieldDetails.end_date === 0) {
      var today = new Date();
      endDate = today.setDate(today.getDate());
    } else {
      endDate = new Date(
        new Date().setDate(new Date().getDate() + fieldDetails.end_date)
      );
    }
  }

  function disableWeekends(date) {
    if (fieldDetails.disabled_days.length !== 0) {
      for (let day of fieldDetails.disabled_days) {
        if (date.getDay() === day.id) {
          return true;
        }
      }
    }
  }

  var date = Boolean(get(formikProps.values, fieldDetails.name))
    ? get(formikProps.values, fieldDetails.name, new Date()) * 1000
    : new Date();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={fieldDetails.label}
        inputFormat="dd/MM/yyyy"
        value={date}
        disabled={disabled}
        onChange={(newDate) => {
          formikProps.setValues((prevVal) => ({
            ...prevVal,
            [fieldDetails.name]: parseInt(new Date(newDate).getTime() / 1000),
          }));
        }}
        minDate={startDate}
        maxDate={endDate}
        shouldDisableDate={disableWeekends}
        renderInput={(params) => (
          <TextField
            {...params}
            required={fieldDetails.mandatory}
            label={fieldDetails.label}
            name={fieldDetails.name}
            formikProps={formikProps}
            disabled={disabled}
          />
        )}
      />
    </LocalizationProvider>
  );
}
