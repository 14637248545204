import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAxios } from "../../hooks";
import { useState } from "react";
import styled from "styled-components";
import { OuterBox } from "../../components";
import { FaUser } from "react-icons/fa";
import { useEffect } from "react";
import { signOutMember } from "../../redux/slices/memberSlice";
import pages from "../../constants/pages";
import Dependent from "./Dependent";
import VerifiedIcon from "@mui/icons-material/Verified";
import NewReleasesIcon from "@mui/icons-material/NewReleases";

const PageWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(to bottom right, #e3f2fd, #f9fbe7);
  padding: 20px;
  align-items: baseline !important;
`;

const ProfileContainer = styled(Card)`
 maxWidth: '800px',
  width: '100%',
  borderRadius: theme.spacing(2),
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  background: 'linear-gradient(to bottom right, #e3f2fd, #f9fbe7)',
  backdropFilter: 'blur(10px)',
  marginBottom: '10vh'
`;

const HeaderButton = styled(Button)`
  color: white;
  background-color: red;
  border: 1px solid red;
  border-radius: 10px;
  padding: 8px 16px;
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ff7961;
    border-color: #ff7961;
  }
`;

const ProfileImage = styled.img`
  width: 47%;
  height: 90%;
  border-radius: 10%;
  border: 4px solid rgb(76, 175, 80);
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
`;

const PlaceholderIcon = styled(FaUser)`
  width: 60%;
  height: 60%;
  border-radius: 50%;
  padding: 20px;
  color: #9e9e9e;
  background: #e0f7fa;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
`;

const InfoLabel = styled(Typography)`
  font-weight: bold;
  color: #757575;
`;

const InfoValue = styled(Typography)`
  font-size: 1.1rem;
  font-weight: bold;
  color: ${({ isPending }) => (isPending ? "red" : "#424242")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* Prevent overflowing the container */
  display: block;
  word-wrap: break-word; /* Allows text to break onto a new line if necessary */
  word-break: break-all; /* Break long words */
  padding-right: 10px; /* Add some padding to avoid text being cut off */
`;

const InfoTable = styled(Table)`
  width: 100%;
  margin-top: 20px;
  font-size: 1rem;
  text-align: left;
`;

export default function Profile() {
  const axios = useAxios();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { visitorData } = useSelector((state) => state.memberDetails);
  const [userImage, setUserImage] = useState("");
  const [dependentList, setDependentList] = useState([]);

  let localStorageData = { ...localStorage };

  const digipassDataKey = Object.keys(localStorageData).filter((item) => {
    return item.includes(visitorData.ID);
  });

  useEffect(() => {
    axios({
      url: "admin/profile/decrypt-face-data",
      method: "POST",
      disableRedirect: true,
      data: {
        face_data: localStorage.getItem(digipassDataKey[0]),
      },
    }).then((response) => {
      if (response.status) {
        setUserImage(response.data);
      }
    });

    axios({
      url:
        "event/visitor/get-dependent-list?phone_number=" +
        visitorData.phoneNumber,
      disableRedirect: true,
    }).then((response) => {
      if (response.status) {
        setDependentList(response.data || []);
      }
    });
  }, [visitorData.ID, axios, dispatch]);

  const handleDeleteData = () => {
    axios({
      url: `admin/profile/delete-face-data?phone_number=${visitorData.phoneNumber}`,
      method: "DELETE",
    }).then((response) => {
      if (response.status) {
        dispatch(signOutMember());
        localStorage.clear();
        navigate(pages.home.route);
      }
    });
  };

  const handleVerifyProfile = (ID) => {
    navigate(
      `${pages.Verification?.route}?visitor_id=${
        ID || visitorData.ID
      }&appointment_id=${-1}&officer_id=${-1}`
    );
  };

  const renderInfoItem = (label, value) => (
    <TableRow>
      <TableCell sx={{ width: "40%" }}>
        <InfoLabel>{label}</InfoLabel>
      </TableCell>
      <TableCell sx={{ width: "60%" }}>
        <Tooltip title={value}>
          <InfoValue isPending={value === "pending"}>{value}</InfoValue>
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  return (
    <PageWrapper>
      <ProfileContainer
        sx={{
          marginBottom: "10vh",
          background: "linear-gradient(to bottom right, #e3f2fd, #f9fbe7)",
        }}
        elevation={0}
      >
        <Grid
          container
          spacing={2}
          sx={{ borderRadius: "10px", background: "white" }}
        >
          <Grid item xs={12} container justifyContent="flex-end">
            {userImage ? (
              <HeaderButton onClick={handleDeleteData}>
                Delete Your Data
              </HeaderButton>
            ) : (
              <HeaderButton onClick={handleVerifyProfile}>
                Verify Profile
              </HeaderButton>
            )}
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            {userImage ? (
              <ProfileImage
                src={`data:image/jpeg;base64,${userImage}`}
                alt="Profile"
              />
            ) : (
              <PlaceholderIcon />
            )}
          </Grid>

          <Grid item xs={12}>
            <InfoTable>
              <TableBody>
                {renderInfoItem("Name", visitorData.firstName || "N/A")}
                {renderInfoItem("Gender", visitorData.gender || "N/A")}
                {renderInfoItem(
                  "Phone Number",
                  visitorData.phoneNumber || "N/A"
                )}
                {renderInfoItem("Email ID", visitorData.emailID || "N/A")}
              </TableBody>
            </InfoTable>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          padding={4}
          sx={{ background: "white", marginTop: "15px" }}
        >
          <Dependent setDependentList={setDependentList} />
        </Grid>

        {dependentList?.map((item, index) => {
          return (
            <Grid
              container
              spacing={2}
              padding={4}
              sx={{ background: "white", marginTop: "15px" }}
            >
              <Grid item>
                <Grid item container>
                  <Typography variant="h6" fontWeight={"900"}>
                    Person : {index + 1}
                    {"  "}
                  </Typography>
                  {item.is_verified ? (
                    <Tooltip title="Verified Profile">
                      <VerifiedIcon sx={{ color: "green" }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Unverified Profile">
                      <NewReleasesIcon
                        sx={{ color: "red" }}
                        onClick={() => handleVerifyProfile(item._id)}
                      />
                    </Tooltip>
                  )}
                </Grid>

                {renderInfoItem("Name", item.first_name + item.last_name)}
                {renderInfoItem("Gender", item.gender)}
                {renderInfoItem("Phone Number", item.phone_number)}
                {renderInfoItem("Email ID", item.email_id)}
              </Grid>
            </Grid>
          );
        })}
      </ProfileContainer>
    </PageWrapper>
  );
}
