import * as Yup from "yup";
import { useFormik } from "formik";
import { sha256 } from "js-sha256";
import { useDispatch } from "react-redux";
// import TextField from "@mui/material/TextField";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef, Fragment } from "react";

import pages from "./../../../constants/pages";
import {
  TextField,
  OuterBox,
  SubheaderSection,
  LoadingButton,
  MandatoryNote,
  OTPInput,
  ButtonContainer,
  ResendOTPButton,
  Loader,
  CaptchaDisplay,
} from "./../../../components";
import {
  useAlert,
  useAxiosV2,
  useAxios,
  useComponent,
} from "./../../../hooks/";
import { CircularProgress, Grid, Tooltip, Typography } from "@mui/material";
import { MdDocumentScanner } from "react-icons/md";

const MyComponent = ({
  token,
  visitorId,
  bookingType,
  setVerificationFailedCount,
  numberOfInputs = 6,
  setVerification,
}) => {
  const axios = useAxios();
  const inputRefs = useRef([]);
  const { alert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const axiosAadhar = useAxiosV2({ baseURL: "aadharUrl" });

  const [uuid, setUuid] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [loading, setLoading] = useState(false);
  const [ocrLoading, setOCRLoading] = useState(false);
  const [aadharNumber, setAadharNumber] = useState("");
  const [aadharOtpGenerated, setAadharOtpGenerated] = useState(false);

  const validationSchema = Yup.object().shape({
    aadhar_number: Yup.string()
      .required("Aadhar Number is required")
      .matches(/^\d{12}$/, "Aadhar Number must be exactly 12 digits"),
    captchaInput: Yup.string()
      .required("Aadhar Captcha Input is required")
      .test("captchaMatch", "Captcha does not match", function (value) {
        return value === captcha;
      }),
  });

  const queryParams = new URLSearchParams(location.search);

  const ref_id = queryParams.get("ref_id");
  const eventId = queryParams.get("event_id");
  const bookingId = queryParams.get("booking_id");
  const visitor_id = queryParams.get("visitor_id");
  const booking_type = queryParams.get("booking_type");
  const appointmentID = queryParams.get("appointment_id");

  const officerID = queryParams.get("officer_id");

  const handleGoBack = () => {
    setVerification(false);
  };

  const handleOtpGoBack = () => {
    setAadharOtpGenerated(false);
  };

  const handleChange = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value !== "" && index < numberOfInputs - 1) {
      newOTP[index + 1] = "";
      setOTP(newOTP);
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      const newOTP = [...otp];
      newOTP[index - 1] = "";
      setOTP(newOTP);
      inputRefs.current[index - 1].focus();
    }
  };

  const generateCaptcha = () => {
    const chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomCaptcha = "";
    for (let i = 0; i < 6; i++) {
      randomCaptcha += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    setCaptcha(randomCaptcha);
  };

  function handleFirstOtp(values) {
    setOTP(new Array(numberOfInputs).fill(""));
    setLoading(true);
    var hashID = sha256(`META5488|KijfgGFw3eMeta|PassMeta`);
    axiosAadhar({
      url: "/enter/aadhar/v2/",
      method: "POST",
      authToken: token,
      data: {
        headers: {
          client_code: "META5488",
          sub_client_code: "META5488",
          function_code: "DEFAULT",
          function_sub_code: "DEFAULT",
        },
        request: {
          sub_client_code: "META5488",
          aadhaar: values.aadhar_number,
          hash: hashID,
        },
      },
    }).then((response) => {
      if (
        response?.data?.code === "333" &&
        response?.data?.message === "Too many requests"
      ) {
        dispatch(
          alert({
            type: "error",
            message: "Please try again in five minutes",
          })
        );
      }

      if (response.response_code === 101) {
        setLoading(false);
        if (response.data.response_status.code === "000") {
          dispatch(
            alert({
              type: "success",
              message: "OTP sent on the registered number.",
            })
          );

          setAadharOtpGenerated(true);
          const data = {
            visitor_id: visitorId,
            aadhar_otp_generated: 1,
            doc_type: "aadhar",
          };
          setUuid(response.data.response_data.uuid);

          // updateVerificationDetails(data);
        } else if (response.data.response_status.code === "471007") {
          dispatch(
            alert({
              type: "error",
              message: "Please enter a valid captcha.",
            })
          );
        } else if (
          response.data?.response_status?.code === "422005" ||
          response.data.response_status.code === "470046"
        ) {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
        }

        if (response.data?.response_status?.status === "FAIL") {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
        }
      } else {
        setLoading(false);
        dispatch(
          alert({
            type: "error",
            message: "Error in sending OTP",
          })
        );
      }
    });
  }

  useEffect(() => {
    generateCaptcha();
  }, []);

  const updateVerificationDetails = (data, step = null) => {
    axios({
      url: `/booking/appointment/verification`,
      method: "PATCH",
      data: { ...data },
    })
      .then((response) => {
        if (response.status) {
          setLoading(false);
          if (step) {
            data.is_verified = 1;
            const payloadString = JSON.stringify(data);
            localStorage.setItem("userPayload", payloadString);

            navigate(pages.checkLiveliness.route, {
              state: {
                visitor_id: visitorId,
                id: eventId,
                ref_id: ref_id,
                booking_type: booking_type,
              },
            });
          }
        }
      })
      .catch((error) => {
        console.error("Error update details:", error);
      });
  };

  const handleVerifyOTP = () => {
    const otpString = otp.join("");
    const numericOtpString = otpString.replace(/\D/g, "");
    const numericOtp = parseInt(numericOtpString, 10);

    if (isNaN(numericOtp) || numericOtpString.length !== 6) {
      dispatch(
        alert({
          type: "error",
          message: "Invalid OTP. Please enter a 6-digit numeric code.",
        })
      );
      return;
    }
    setLoading(true);
    axiosAadhar({
      url: "/enter/otp/v2/",
      method: "POST",
      authToken: token,
      data: {
        headers: {
          client_code: "META5488",
          sub_client_code: "META5488",
          function_code: "DEFAULT",
          function_sub_code: "DEFAULT",
        },
        request: {
          uuid: uuid,
          otp: numericOtp.toString(),
        },
      },
    }).then((response) => {
      if (response.response_code === 101) {
        if (
          response.data?.response_status?.code === "422002" ||
          response.data?.response_status?.code === "422003" ||
          response.data?.response_status?.code === "333" ||
          response.data?.response_status?.code === "470021"
        ) {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
          setVerificationFailedCount((prevCount) => prevCount + 1);
          setLoading(false);
        } else if (response.data?.response_status?.code === "000") {
          const name = response.data.response_data.name;
          const nameParts = name.split(" ");
          const first_name = nameParts[0];
          let last_name = nameParts.slice(1).join(" ");
          if (last_name.trim() === "") {
            last_name = first_name;
          }
          const initialDate = response.data.response_data.dob;
          const [day, month, year] = initialDate.split("/");
          const formattedDate = `${day}-${month}-${year}`;
          let payload = {
            doc_type: 1,
            booking_type: "event",
            aadharNo: response.data.response_data.document_id,
            visitor_id: visitorId,
            first_name: first_name,
            last_name: last_name,
            gender: response.data.response_data.gender,
            dob: formattedDate,
            address: response.data.response_data.address,
            doc_face_image: response.data.response_data.doc_face,
          };
          const step = 1;
          // updateVerificationDetails(payload, step);

          if (step) {
            payload.is_verified = 1;
            const payloadString = JSON.stringify(payload);
            localStorage.setItem("userPayload", payloadString);

            navigate(
              pages.checkLiveliness.route +
                (bookingId ? "/?booking_id=" + bookingId : "") +
                (appointmentID ? "/?appointment_id=" + appointmentID : "") +
                (eventId ? "&event_id=" + eventId : "") +
                (officerID ? "&officer_id=" + officerID : ""),
              {
                state: {
                  visitor_id: visitorId,
                  id: eventId,
                  appointmentData: location?.state?.details || {},
                },
              }
            );
          }
        } else {
          dispatch(
            alert({
              type: "error",
              message: response.data?.response_status?.message,
            })
          );
          setVerificationFailedCount((prevCount) => prevCount + 1);
          setLoading(false);
        }
      }
    });
  };

  const [otp, setOTP] = useState(new Array(numberOfInputs).fill(""));

  const onSubmit = async (values) => {
    try {
      handleFirstOtp(values);
    } catch (error) {
      dispatch(
        alert({
          type: "error",
          message: error,
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      aadhar_number: "",
      captchaInput: "",
    },
    validationSchema,
    onSubmit,
  });

  const handleAadharNumberChange = (event) => {
    const inputNumber = event.target.value;
    const cleanedInput = inputNumber.replace(/\D/g, "");
    const truncatedInput = cleanedInput.slice(0, 12);
    formik.handleChange(event);
    formik.setFieldValue("aadhar_number", truncatedInput);
    setAadharNumber(truncatedInput);
  };

  if (!aadharOtpGenerated) {
    return (
      <Grid item padding={"25px"} sl={6} alignContent={"center"}>
        <SubheaderSection
          textAlign="center"
          title="Aadhar Verification"
          content="Enter your Aadhar details to continue"
        />

        <form>
          <TextField
            fullWidth
            sx={{ marginBottom: "16px" }}
            variant="outlined"
            label={
              <Fragment>
                <Grid container>
                  <Grid item xs={10}>
                    Enter 12 Digit Aadhar Number
                    <span
                      style={{
                        color: "red",
                        float: "right",
                        paddingLeft: "5px",
                      }}
                    >
                      *
                    </span>
                  </Grid>
                </Grid>
              </Fragment>
            }
            placeholder="Enter Aadhar Card Number"
            name="aadhar_number"
            value={formik.values.aadhar_number}
            onChange={handleAadharNumberChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.aadhar_number &&
              Boolean(formik.errors.aadhar_number)
            }
            InputProps={{
              endAdornment: (
                <Fragment>
                  <Tooltip title="Upload Document">
                    <input
                      accept="image/*,application/pdf"
                      style={{ display: "none" }}
                      id="file-upload"
                      type="file"
                      onChange={(event) => {
                        const fil = event.target.files[0];
                        setOCRLoading(true);
                        const data = new FormData();
                        data.append("file", fil);
                        axios({
                          url: "event/govt-api/ocr",
                          method: "POST",
                          data: data,
                        }).then((response) => {
                          formik.setFieldValue(
                            "aadhar_number",
                            response.data?.data?.number || ""
                          );

                          setOCRLoading(false);
                          if (!response.data?.data?.number) {
                            dispatch(
                              alert({
                                type: "error",
                                message: "Please share the right documents",
                              })
                            );
                          }
                        });
                      }}
                    />
                    <label htmlFor="file-upload">
                      {!ocrLoading ? (
                        <MdDocumentScanner
                          style={{
                            color: "rgb(161, 130, 74)",
                            fontSize: "35px",
                            background: "white",
                            borderRadius: "7px",
                            padding: "3px 5px",
                            width: "100%",
                            cursor: "pointer",
                            border: "1px solid rgb(161 130 74 / 55%)",
                          }}
                        />
                      ) : (
                        <CircularProgress
                          sx={{ color: "rgb(161, 130, 74)", fontSize: "35px" }}
                        />
                      )}
                    </label>
                  </Tooltip>
                </Fragment>
              ),
            }}
          />

          <TextField
            label={
              <Fragment>
                Enter Captcha
                <span
                  style={{
                    color: "red",
                    float: "right",
                    paddingLeft: "5px",
                  }}
                >
                  *
                </span>
              </Fragment>
            }
            fullWidth
            margin="normal"
            variant="outlined"
            placeholder="Enter Captcha"
            name="captchaInput"
            value={formik.values.captchaInput}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.captchaInput && Boolean(formik.errors.captchaInput)
            }
            helperText={formik.errors.captchaInput}
            inputProps={{
              maxLength: 6,
            }}
            autoComplete="off"
          />

          <CaptchaDisplay captcha={captcha} generateCaptcha={generateCaptcha} />

          <ButtonContainer>
            <LoadingButton loading={loading} onClick={formik.handleSubmit}>
              Send OTP
            </LoadingButton>

            <LoadingButton
              onClick={handleGoBack}
              backgroundColor="#F5F0E5"
              style={{ color: "black" }}
            >
              Back
            </LoadingButton>
          </ButtonContainer>

          <MandatoryNote />
        </form>
      </Grid>
    );
  }

  return (
    <Loader>
      <Grid item padding={"25px"}>
        <SubheaderSection
          textAlign="center"
          title="Aadhar Verification"
          content="Enter the 6 digit OTP received on your Aadhar linked mobile number"
        />

        <Typography textAlign={"center"}>
          <span style={{ fontWeight: 600, color: "rgb(161, 130, 74)" }}>
            Your Aadhar Number :
          </span>{" "}
          <span style={{ fontWeight: 800, color: "rgb(161, 130, 74)" }}>
            {" "}
            {` xxxxxxxx${aadharNumber.slice(8)}`}
          </span>
        </Typography>

        <form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              marginTop: "20px",
            }}
          >
            {otp.map((value, index) => (
              <OTPInput
                key={index}
                index={index}
                value={value}
                otp={otp}
                inputRefs={inputRefs}
                otpLength={numberOfInputs}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>

          <ButtonContainer>
            <LoadingButton loading={loading} onClick={handleVerifyOTP}>
              Verify Aadhar
            </LoadingButton>

            <LoadingButton onClick={handleOtpGoBack} backgroundColor="gray">
              Back
            </LoadingButton>
          </ButtonContainer>

          <ResendOTPButton onClick={formik.handleSubmit} disabled={loading} />
        </form>
      </Grid>
    </Loader>
  );
};

export default MyComponent;
